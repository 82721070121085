import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Folio.styles";
import { GizmoWrapper } from "../../utils";

type FolioProps = {
  gizmo: Gizmo;
};

const Folio: FC<FolioProps> = ({ gizmo }) => {
  const [folio, setFolio] = useState<string | undefined>();
  const { config, binder, features, errors } = useGizmo({ gizmo });

  useEffect(() => {
    if (binder) {
      const value = binder.getValue();
      if (value) {
        setFolio(value);
      }
    }
  }, [binder]);

  return (
    <GizmoWrapper features={features} errors={errors}>
      <S.Folio id={`folio-${config.fid}`} columns={gizmo.getColumns()}>
        {folio && <h3>{folio}</h3>}
      </S.Folio>
    </GizmoWrapper>
  );
};

export default Folio;
