import { IFlowyProject, IGizmo } from "flowy-3-core";

const viewWithFormsIndex: IGizmo = {
  fid: "view-with-forms-index",
  type: "view",
  label: "View with forms index",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    // {
    //   fid: "g0",
    //   type: "text",
    //   label: "Forms Index",
    // },
    {
      fid: "g1",
      type: "index",
      label: "Formatos",
      ops: {
        index: {
          // type: "table",
          rowType: "forms",
        },
      },
    },
    // {
    //   fid: "g2",
    //   type: "button",
    //   label: "Go to registers index",
    //   ops: {
    //     button: {
    //       operation: "navigation.link",
    //       navigation: {
    //         path: "/r",
    //       },
    //     },
    //   },
    // },
  ],
};

const viewWithRegistersIndex: IGizmo = {
  fid: "view-with-registers-index",
  type: "view",
  label: "View with registers index",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    // {
    //   fid: "g0",
    //   type: "text",
    //   label: "Registers Index",
    // },
    {
      fid: "g1",
      type: "index",
      label: "Registers Index",
      ops: {
        index: {
          rowType: "form.registers",
          operations: [
            {
              label: "Ver",
              type: "register.show",
            },
            // {
            //   label: "Editar",
            //   type: "register.edit",
            // },
          ],
        },
      },
    },
  ],
};

const viewNavbar: IGizmo = {
  fid: "view-navbar",
  type: "view",
  label: "View with navbar",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "g100",
      type: "row",
      label: "Row",
      ops: {
        row: {
          columns: {
            xl: 4,
            l: 4,
            m: 3,
            s: 2,
          },
        },
      },
      gizmos: [
        // {
        //   fid: 'g3',
        //   // TODO: change to image
        //   type: 'button',
        //   label: 'Home'
        // },
        {
          fid: "g4",
          type: "text",
          label: "",
        },
        {
          fid: "g1",
          type: "text",
          // label: "Username",
          label: "usuario: {{user.username}}",
        },
        {
          fid: "g0",
          type: "button",
          label: "Logout",
          ops: {
            button: {
              operation: "auth.logout",
              confirmation: {
                title: "Cerrar sesión",
                description: "¿Estás seguro que quieres cerrar sesión?",
              },
            },
          },
        },
      ],
    },
  ],
};

const defaultProject: IFlowyProject = {
  fid: "deafult-project",
  type: "project",
  label: "Default project",
  views: {
    gizmos: [viewWithFormsIndex, viewWithRegistersIndex],
  },
  navbar: {
    gizmos: [viewNavbar],
  },
  navigation: {
    routes: [
      {
        fid: "r1",
        type: "route",
        label: "Forms Index",
        uid: "forms_index",
        ops: {
          route: {
            rootGizmoFid: viewWithFormsIndex.fid,
            index: true,
          },
        },
      },
      {
        fid: "r2",
        type: "route",
        label: "Registers Index",
        uid: "registers_index",
        ops: {
          route: {
            rootGizmoFid: viewWithRegistersIndex.fid,
            index: false,
            path: "/r",
            // TODO: parameters definition
          },
        },
      },
    ],
  },
};

export default defaultProject;
