import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo, IGizmoCatalogOptionV2 } from "flowy-3-core";
import { GizmoWrapper } from "../../utils";
import * as S from "./SelectField.styles";
import { Input } from "antd";
import { BooleanField } from "../BooleanField/BooleanField.styles";

type SelectFieldProps = {
  gizmo: Gizmo;
};

const SelectField: FC<SelectFieldProps> = ({ gizmo }) => {
  const { features, config, binder, errors } = useGizmo({ gizmo });
  const [options, setOptions] = useState<IGizmoCatalogOptionV2[] | undefined>();
  const [selected, setSelected] = useState<any[]>([]);
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    if (binder) {
      const initOptions: IGizmoCatalogOptionV2[] = binder.getOptions();
      setOptions(initOptions);

      binder.options.subscribe((options: IGizmoCatalogOptionV2[]) => {
        setOptions(options);
      });
      binder.selectedOptions.subscribe((selected: IGizmoCatalogOptionV2[]) => {
        setSelected(selected);
      });
      binder.otherValue.subscribe((otherValue: string) => {
        setOtherValue(otherValue);
      });

      const initSelected: IGizmoCatalogOptionV2[] = binder.getSelected();
      if (initSelected.length > 0) {
        setSelected(initSelected);

        const initOther = binder.getOtherValue();
        if (initOther) {
          setOtherValue(initOther);
        }
      }
    }
  }, [binder, config]);

  const handleSelectChange = async (value: any) => {
    value = config?.ops?.selectField?.isMultiple ? value : [value];
    let option = options?.filter((option) => value.includes(option.value));
    if (option && binder) {
      if (
        config.ops?.selectField?.controlType === "radio" &&
        selected.length &&
        option[0].value === selected[0].value
      )
        option = [];

      await binder.setSelected(option);
    }
  };

  /**
   * If the user selects other a new input will appear to write a custom value.
   *
   * @param event event with the user input value
   */
  const handleInputChange = async (event: any) => {
    if (binder) await binder.setOtherValue(event.target?.value);
  };

  const handleClear = async () => {
    await binder.setSelected([]);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {features.editable === false ? (
        <>
          {selected.map((option, i) => (
            <span className="notranslate" key={i}>
              {option.value}{option.f_id === 'o0' && `: ${binder.getOtherValue()}`}
            </span>
          ))}
          <br />
        </>
      ) : config.ops?.selectField?.controlType === "radio" ? (
        <>
          {options?.map((option, i) => (
            <BooleanField
              key={i}
              value={option.value}
              checked={selected.some((e: any) => e.value === option.value)}
              onChange={() => handleSelectChange(option.value)}
            >
              {option.value}
            </BooleanField>
          ))}
        </>
      ) : (
        <>
          <S.SelectField
            showSearch={config.ops?.selectField?.search}
            id={`select_field-${config.fid}`}
            onChange={handleSelectChange}
            mode={config?.ops?.selectField?.isMultiple ? "multiple" : undefined}
            allowClear
            onClear={handleClear}
            value={selected}
            disabled={!options?.length}
          >
            {options?.map((option, i) => (
              <S.SelectField.Option key={i} value={option.value}>
                {option.value}
              </S.SelectField.Option>
            ))}
          </S.SelectField>

          {config.ops?.selectField?.other && selected[0]?.f_id === "o0" && (
            <Input
              style={{ marginTop: 10 }}
              onChange={handleInputChange}
              value={otherValue}
            />
          )}
        </>
      )}
    </GizmoWrapper>
  );
};

export default SelectField;
