import { IGizmoFeatures } from "flowy-3-core";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const GizmoLabel = (features: IGizmoFeatures) => {

  return features.help?.type === "tooltip" ? (
    <span>
      {features.label + " "}
      <Popover content={<span>{features.help.message}</span>}>
        <QuestionCircleOutlined />
      </Popover>
    </span>
  ) : (
    <span>{features.label}</span>
  )
};

export default GizmoLabel;
