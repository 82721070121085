import React, { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo, IIndex, IIndexColumn } from "flowy-3-core";
import downloadBatch from "./downloadBatch";
import downloadPdf from "./downloadPdf";
import Search from "./Search";
import * as S from "./Index.styles";
import { Button, Divider, TablePaginationConfig } from "antd";
import { Table } from "../../daq/AnnexForm/AnnexForm.styles";

type IndexProps = {
  gizmo: Gizmo;
};

const Index: FC<IndexProps> = ({ gizmo }) => {
  const { features, config, binder } = useGizmo({ gizmo });
  const [label, setLabel] = useState<string>('');
  const [columns, setColumns] = useState<IIndexColumn[] | undefined>();
  const [rows, setRows] = useState<any[] | undefined>();
  const [pagination, setPagination] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (binder) {
      setLoading(true)
      const index: IIndex | undefined = binder.getIndex();
      if (index) {
        if (index.label) {
          setLabel(index.label);
        } else {
          setLabel(features.label);
        }
        setColumns(index.columns);
        setRows(index.rows);
        setPagination(index.pagination);
      }
      setLoading(false)
    }
  }, [binder]);

  const handleCellClick = async ({
    column,
    row,
    action,
    operation,
  }: IHandleCellClick) => {
    if (action === "pdf.download") {
      await downloadPdf({
        operation: action,
        gizmo,
        row,
        column,
      });
    } else if (operation) {
      await binder.cellInteraction({ column, row, operation });
    } else {
      await binder.cellInteraction({ column, row });
    }
  };

  const handlePageChange = async (pageData: TablePaginationConfig) => {
    setLoading(true)
    await binder.pageChange({ page: pageData.current });
    const index: IIndex | undefined = binder.getIndex();

    if (index) {
      setColumns(index.columns);
      setRows(index.rows);
      setPagination(index.pagination);
    }
    setLoading(false)
  };

  /**
   * Callback for search results coming from the server
   *
   * @param {IIndex} index search result
   */
  const handleSearchResult = (index: IIndex) => {
    setColumns(index.columns);
    setRows(index.rows);
    setPagination(index.pagination);
  };

  const handleCreateButtonClick = async () => {
    await binder.dispatchOperation({
      operation: "navigation.link",
      params: {
        path: `/rg?rgfid=${gizmo.getParams().fid}`,
      },
    });
  };

  const handleExportButtonClick = async () => {
    setExportDisabled(true);
    await downloadBatch({ gizmo });
    setExportDisabled(false);
  };

  const renderColumn = (column: IIndexColumn) => {
    const col: any = {
      title: column.label,
      key: column.fid,
    };

    if (column.type === undefined) {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        if (row.answers) {
          return (<span
            className="notranslate"
          >
            {data?.v}
          </span>
          );
        }

        return (
          <span
            style={{ cursor: "pointer", textDecoration: 'underline' }}
            onClick={async () => handleCellClick({ column, row })}
            className="notranslate"
          >
            {data?.v}
          </span>
        );
      };
    } else if (column.type === "operations") {
      col.render = (row: any) => {
        const operations = binder.getCell({ column, row });

        return operations.map((operation: any, index: number) => (
          <span key={index}>
            {index > 0 && <Divider type="vertical" />}
            <Button
              key={operation.f_id}
              onClick={() => handleCellClick({ column, row, operation: operation.op })}/*  handleOperationButtonClick(d, row) */
            >
              {operation.v}
            </Button>
          </span>
        ));
      };
      col.width = "35%"
      col.align = "center"
    }

    return col;
  };

  return (
    <S.Index id={`index-${config.fid}`} columns={gizmo.getColumns()}>
      <h2>{label}</h2>
      {/* {config.ops?.index?.rowType === "form.registers" && (
        <Search binder={binder} onSearchResult={handleSearchResult} />
      )} */}
      {config.ops?.index?.rowType === "form.registers" &&
        gizmo.getParams().fid && (<>
          <Divider type="vertical" />
          <Button onClick={handleCreateButtonClick}>Crear</Button>
        </>
        )}
      {/* {config.ops?.index?.rowType === "form.registers" &&
        gizmo.getParams().fid && (
          <>
            <Divider type="vertical" />
            <Button onClick={handleExportButtonClick} disabled={exportDisabled}>
              Exportar
            </Button>
          </>
        )} */}

      {columns && rows && (
        <Table
          columns={columns?.map(renderColumn)}
          dataSource={rows?.map((row) => row)}
          loading={loading}
          pagination={{
            pageSize: pagination.pageSize,
            total: pagination.total,
            current: pagination.page
          }}
          onChange={handlePageChange}
          rowKey="id"
        />
      )}

    </S.Index>
  );
};

interface IHandleCellClick {
  column: IIndexColumn;
  row: any;
  action?: string;
  operation?: any;
}

export default Index;
