import "react-toastify/dist/ReactToastify.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { FlowyGlobalProvider } from "flowy-3-core";
import { FlowyNotificationsProvider } from "./components/providers";
import Development from "./components/dev/Development";
import {
  DefaultProject,
  PublicRootGizmo,
  Login,
  PlatformInterface,
  BaseProject,
} from "./client";
import { PrivateRoutes, RedirectToRoot } from "./client/routes";
import config from "./config";
import { antdTheme } from "./client/gizmoConfigurations";
import locale from 'antd/locale/es_ES';
import ApprovedSearch from "./ApprovedSearch";
import Closed from "./Closed";
import './index.css'

function App() {
  return (
    <div className="notranslate">
      <span>v0.0.22-f</span>
      <FlowyGlobalProvider
        apiUrl={config.apiUrl}
        loadingComponent={<div>Loading...</div>}
        platformInterface={PlatformInterface}
      >
        <ConfigProvider
          theme={antdTheme}
          locale={locale}
        >
          <FlowyNotificationsProvider>
            <Router>
              <Routes>
                {/* <Route path="/" element={<Development />} /> */}
                {/* <Route path="/*" element={<BaseProject />} /> */}
                {/* <Route path="/*" element={<ApprovedSearch />}/> */}
                {/* <Route path="/*" element={<Closed />}/> */}

                <Route element={<PrivateRoutes />}>
                  <Route path="/*" element={<DefaultProject />} />
                </Route>
                <Route path="/p/rg" element={<PublicRootGizmo />} />
                <Route element={<RedirectToRoot />}>
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>
            </Router>
          </FlowyNotificationsProvider>
        </ConfigProvider>
      </FlowyGlobalProvider>
    </div>
  );
}

export default App;
